
/*!--------------------------------------------------------
 * updater
 * --------------------------------------------------------
 * @depends jquery.js
 * @depends jquery.pubsub.js
 * @depends jquery.ui.selectmenu.js
 */
module.exports = (function($, I18n) {
  'use strict';

  // settings
  var s = {
    locale: 'en',
    selector: '.m-updater',
    trash: undefined,
    disableAdd: []
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
  */
  function init(opts) {
    // recursively merge opts into settings
    $.extend(true, s, opts);
    s.locale = opts.locale || s.locale;

    $.subscribe('/updater/add', add);

    // specific subscription for when module
    // hasn't been initialised but is necessary
    // within generic /add's context. generic
    // /add inits module and then publishes
    // specific subscription
    $.subscribe('/updater/add/updater', add);
  }

  /* --------------------------------------------------------
   * add
   * --------------------------------------------------------
   * adds updater functionality to context
  */
  function add(context) {
    var context = $(context);

    context.on('click.updater', s.selector + '-remove', remove);
    context.on('click.updater', s.selector + '-remove-template', removeTemplate);
    context.on('click.updater', s.selector + '-add',    insert);
    context.on('click.updater', s.selector + '-save',   submit);
    context.on('click.updater', s.selector + '-edit',   edit);

    updateAddTriggers(context);
    updateAltTriggers(context);
    updateData(context);
  }

  /* --------------------------------------------------------
   * insert
   * --------------------------------------------------------
   * inserts new empty version of template
  */
  function insert(e) {
    var trigger = $(e.target),
      updaters = trigger.parents(s.selector),
      multiple = trigger.closest(s.selector + '-multiple'),
      wrappers = multiple.children(s.selector),
      data = updaters.eq(updaters.length-1).data() || {},
      addButton = trigger.closest(s.selector + '-add');

    if (trigger.closest(s.selector + '-no-parent').length) {
      wrappers = [trigger.closest(s.selector + '-no-parent').find(s.selector)];
      multiple = wrappers[0];
    }

    if (!wrappers.length) {
      wrappers = multiple.children(s.selector + '-parent');
    }

    e.updater = e.updater || 'add';
    e.wrapper = e.wrapper || wrappers.eq(wrappers.length - 1);

    // disable add button if that's what was being triggered
    disableButton(addButton);

    if (!e.wrapper.length) {
      e.wrapper = trigger.closest(s.selector);
    }

    data = $.extend({}, data, trigger.data());

    var endpoint = trigger.closest(".edit_form").find("[name=endpoint]");
    if(endpoint.length) {
      data.endpoint = endpoint.val();
    }

    if(multiple.length && !e.wrapper.parentsUntil(multiple, s.selector).length) {
      data.field = multiple.data('field');
      data.enable_meddra = multiple.data('enable_meddra');
    } else {
      data.field = e.wrapper.data('field');
      data.enable_meddra = e.wrapper.data('enable_meddra');
    }

    e.preventDefault();
    e.stopPropagation();

    return template(e, data, update).fail(function() {
      disableButton(addButton, false);
    });
  }

  function toggleNoAdverseEventButton(){
    if($('#adverse_event-fieldset').children().length){
      $('button.no-ae-button').prop('disabled', true)
    } else {
      $('button.no-ae-button').prop('disabled', false)
    }
  }

  /* --------------------------------------------------------
   * removeTemplate
   * --------------------------------------------------------
   * removes template form
   */
  function removeTemplate(e) {
    var $trigger = $(e.target),
      $template = $trigger.closest(s.selector + '-parent'),
      id = $template.attr('id'),
      data = $template.data(),
      $button = $trigger.closest(s.selector + '-remove-template'),
      uri = $trigger.data('uri') || $trigger.parent().data('uri'),
      report_id = $(e.target).closest("form").find("#report_id").val();

    if (!$template.length) {
      $template = $trigger.closest(s.selector + '-parent-template');
      id = $template.attr('id');
      data = $template.data();
    }

    if(!confirm(I18n.t('javascript.remove_confirm_advanced'))) {
      return false;
    }

    disableButton($button);

    e.preventDefault();
    e.stopPropagation();

    data = $.extend({}, data, {id: id}, {report: {id: report_id}});
    data.templatePath = '/' + I18n.locale + '/report/'+uri;

    fetchTemplate('post', data).done(function () {
      $template.detach();
      updateTriggerMessage('post', data);
      $.publish('/updater/remove_template', [$template]);
      $.publish('/updater/add/deathToggler', [document]);
      $.publish('/updater/add/conditionalMultiChoices', [$template]);
      toggleNoAdverseEventButton();
    }).fail(function () {
      disableButton($button, false);
    });

  }

  /* --------------------------------------------------------
   * remove
   * --------------------------------------------------------
   * removes template
  */
  function remove(e) {
    var trigger = $(e.target),
      multiples = trigger.parents(s.selector + '-multiple'),
      updater = trigger.closest(s.selector + '-parent'),
      data = updater.data() || {},
      inLightbox = updater.closest('#colorbox').length,
      button = trigger.closest(s.selector + '-remove'),
      updaterSiblings;
    var report_id = $(e.target).closest("form").find("#report_id").val();

    if (trigger.closest(s.selector + '-no-parent').length) {
      updater = trigger.closest(s.selector + '-no-parent').find(s.selector);
      data = updater.data() || {}
      inLightbox = updater.closest('#colorbox').length;
    }

    if (!updater.length) {
      updater = trigger.closest(s.selector)
      data = updater.data() || {}
      inLightbox = updater.closest('#colorbox').length;
    }

    e.preventDefault();
    e.stopPropagation();

    // disable remove button if that's what was being triggered
    disableButton(button);

    e.updater = e.updater || 'remove';
    e.wrapper = e.wrapper || updater;
    updaterSiblings = e.wrapper.siblings(s.selector + '-parent');

    if (!updaterSiblings.length) {
      updaterSiblings = e.wrapper.siblings(s.selector);
    }

    if (!inLightbox && !updater.hasClass('no_remove_popup')) {
      if (!confirm(I18n.t('javascript.remove_confirm_advanced'))) {
        disableButton(button, false);
        return;
      }
    }

    // create a trash div to store
    // removed elements. need them to
    // remain on the page
    s.trash = s.trash ||
      $('<div style="display: none;" />')
        .appendTo(e.wrapper.closest('form'));

    data = $.extend({}, data, trigger.data(), {report: {id: report_id}});
    data.templatePath = '/' + s.locale + '/report/remove_component';

    fetchTemplate('post', data).done(function(){
      if (!updaterSiblings.length || (updaterSiblings.length && !multiples.length)) {
        // it's the last one so we need to add the
        // empty template back in
        insert(e);
      } else if (updaterSiblings.length == 1 && multiples.find(s.selector + '-editable').length) {
        updater.remove();
        insert(e);
      } else {
        // remove template from ui
        updateTriggerMessage('post', data)
        update(e, '');
      }
      $.publish('/updater/add/assessmentToggler', [multiples]);
    }).fail(function() {
      // enable remove button if that's what was being triggered
      disableButton(trigger.closest(s.selector + '-remove'), false);
      $.publish('/updater/remove', [trigger, data]);
    });
  }

  /* --------------------------------------------------------
   * submit
   * --------------------------------------------------------
   * submits wrapper data in order to retrieve
   * updated/readonly template
  */
  function submit(e, data) {
    data = data || {};

    var trigger = $(e.target),
      updaters = trigger.parents(s.selector),
      saveButton = trigger.closest(s.selector + '-save'),
      editForm = $(s.selector).closest('.edit_form');

    e.updater = e.updater || 'submit';
    e.wrapper = e.wrapper || trigger.closest(s.selector + '-parent-template');

    if(!e.wrapper.length) {
      e.wrapper = trigger.closest(s.selector + '-parent');

      if(!e.wrapper.length) {
        e.wrapper = trigger.closest(s.selector);
      }
    }

    if (e.wrapper.is(':first-child')) {
      data.first_template = true;
    }

    $.publish('/updater/beforeAdd/assessmentToggler', [e.wrapper]);

    if (editForm.length) {
      $.publish('/autosuggest/form/changed', [editForm]);
    }

    if (editForm.find('.m-errors').length) {
      return $.Deferred().reject();
    }

    // disable submit button if that's what was being triggered
    disableButton(saveButton);
    // Enable those hidden selectmeuns so serializeArray() can get the value
    e.wrapper.find('select:disabled').removeAttr('disabled');
    data = $.extend({}, data, updaters.eq(updaters.length-1).data());
    data = $.extend({}, data, e.wrapper.find(':input').serializeObject());
    data.field = e.wrapper.data('field');
    data.event = data.event || 'submit';

    e.preventDefault();
    e.stopPropagation();

    return template(e, data, update).fail(function() {
      disableButton(saveButton, false);
      $.publish('/updater/submit', [trigger, data]);
    });
  }

  /* --------------------------------------------------------
   * edit
   * --------------------------------------------------------
   * submits wrapper data in order to retrieve
   * editable template
  */
  function edit(e) {
    var trigger = $(e.target),
      oldValues;

    e.updater = e.updater || 'edit';
    e.wrapper = e.wrapper || trigger.closest(s.selector);
    if(!e.wrapper.length) {
      e.wrapper = trigger.closest('.item-edit').find(s.selector);
    }
    // Enable those hidden selectmeuns so serializeArray() can get the value
    e.wrapper.find('select:disabled').removeAttr('disabled');
    e.oldData = $('<form />').append(e.wrapper.clone()).serializeArray();
    // Convert all '&the_dot' to '.' for display purpose
    e.oldData = e.oldData.map(function(data, _index) {
      data['value'] = data.value.replace(/&the_dot;/g, '.');
      return data
    });
    if (trigger.data('updaterKeepValueWhenEditing')) {
      $.publish('/updater/edit', [e.wrapper, e.oldData]);
    } else {
      // disable edit button if that's what was being triggered
      disableButton(trigger.closest(s.selector + '-edit'));
      submit(e, { event: 'edit' });
    }
    return false;
  }

  /* --------------------------------------------------------
   * update
   * --------------------------------------------------------
   * updates ui with new html
  */
  function update(e, html) {
    // add new html after wrapper
    var el = (html ? $(html).insertAfter(e.wrapper).not('script') : []);

    if (e.updater === 'remove') {
      var multiple = e.wrapper.closest(s.selector + '-multiple');

      // publish destroy to remove unnecessary
      // dom elements created by plugins/modules
      $.publish('/updater/destroy', [e.wrapper]);
      $.publish('/updater/remove', [e.wrapper]);
      $.publish('/updater/remove_template', [e.wrapper]);

      // remove the old wrapper
      if (e.wrapper.find('.ruby-remove').length) {
        e.wrapper
          .appendTo(s.trash)
          .find('.ruby-remove')
          .prop('checked', true);
      } else {
        e.wrapper.remove();
      }

      if (multiple.length) {
        updateAddTriggers(multiple);
        updateAltTriggers(multiple);
        //updateData(multiple);
      }
    }

    if (e.updater === 'submit' || e.updater === 'edit') {
      // This should really be #remove() but IE7
      // will crash when trying to fully remove the element.
      e.wrapper.detach();
      // publish destroy to remove unnecessary
      // dom elements created by plugins/modules
      $.publish('/updater/destroy', [e.wrapper]);
      // @todo: rename, not really ajax_template
      var id = el.attr('id');
      if (el.length && !(id != "" && $("a.m-jump-to[data-id=" + id + "]").length)) {
        $.publish('/updater/add/ajax_template', [el, '.click-thru-links-other']);
      }
      if (el.length) {

        /**
         * Use `attr` to make the data appear in the HTML, where `data` would add the data invisibly
         * 1. selective makes sure it doesn't call initRadioCheckUncheckEvents which cause the button to not be
         * unselected
         * 2. enable_meddra is supposed to be passed to the data object when requesting template
         * enforcing the response to contain an autocomplete input
         */
        el.attr({
          'data-selective': true,
          'data-enable_meddra': true
        });

        $.publish('/updater/add', [el]);
        $.publish('/updater/submit', [el]);

        if (e.updater === 'edit') {
          $.publish('/updater/edit', [el, e.oldData]);
        }
      }
    }

    if (el.length) {
      var error = el.closest('.field_with_errors'),
        content;

      // if the field was errored before update
      // then remove the error styling
      if (error.length) {
        content = error.children().insertAfter(error);
        error.remove();
      }

      if (e.updater !== 'submit' && e.updater !== 'edit') {
        $.publish('/updater/add', [el]);
      }
    }
    if ($(el).closest('.edit_form').length) {
      $.publish('/autosuggest/form/changed', [$(el).closest('.edit_form')]);
    }
    $.publish('/updater/add/deathToggler', [document]);
    $.publish('/updater/add/conditionalMultiChoices', [el]);
    $.publish('/updater/update/jump_to_link', [el]);
    $.publish('/updater/add/assessmentToggler', [el]);
  }

  function isCurrentlyEditing(context) {
    var editing = false,
      editableFields, editable;

    if (context.data('multiple-disable-on-edit')) {
      editableFields = context.find(s.selector + '-editable:visible :input:not(button)');
      editableFields.each(function () {
        editing = !!$(this).val();
      });
    } else {
      editable = context.find(s.selector + '-editable:visible');
      editing = !!editable.length;
    }

    return editing;
  }

  function updateButtonTriggers(context, button, options) {
    var mClass = s.selector + '-multiple',
      aClass = s.selector + '-' + button,
      multiple, disableAdd;

    options = $.extend({
      disableWhenEditing: false,
      disableWhenSaved: false
    }, options || {});

    function closest(target, selector) {
      var found = target.parents(selector);
      found = found.length ? found.first() : target.closest(selector);
      found = found.length ? found : target.findMe(selector);
      return found;
    }

    multiple = closest(context, mClass);

    multiple.find(aClass).each(function(i, button) {
      var disableWhenEditing = options.disableWhenEditing,
        disable = false,
        buttonMultiple;

      button = $(button);
      buttonMultiple = closest(button, mClass);

      if (typeof buttonMultiple.data('add-when-editing') !== 'undefined') {
        disableWhenEditing = !!buttonMultiple.data('add-when-editing');
      }

      if (disableWhenEditing) {
        disable = isCurrentlyEditing(buttonMultiple);
      }

      if (options.disableWhenSaved) {
        disable = !!buttonMultiple.find(s.selector + '-saved:visible').length;
      }

      var other_indication_field = context.find('.other-indication-field');
      if (!other_indication_field.length) {
        other_indication_field = context.closest('.other-indication-field');
      }

      if (other_indication_field.length) {
        disable = false;
      }

      disableButton(button, disable);
    });
  }

  /* --------------------------------------------------------
   * updateAddTriggers
   * --------------------------------------------------------
   * updates add buttons enabled/disabled state
  */
  function updateAddTriggers(context) {
    updateButtonTriggers(context, 'add', {
      disableWhenEditing: true
    });
  }

  function updateAltTriggers(context) {
    updateButtonTriggers(context, 'alt', {
      disableWhenSaved: true
    });
  }

  /* --------------------------------------------------------
   * disableButton
   * --------------------------------------------------------
   * disables button if condition is true
  */
  function disableButton(button, condition) {
    // if no condition is passed then
    // assume we are trying to disable
    condition = condition == null ? true : condition;
    if (button && button.length) {
      button[condition ? 'addClass' : 'removeClass']('disabled').prop('disabled', condition);
    }
  }

  /* --------------------------------------------------------
   * updateData
   * --------------------------------------------------------
  */
  function updateData(el) {
    var updaters = el.parents(s.selector).length ? el.parents(s.selector) : el.closest(s.selector),
      data = el.find(s.selector + '-data').serializeObject(),
      furthest = updaters.eq(updaters.length-1);

    if (furthest.length && !$.isEmptyObject(data)) {
      // there has been a data change
      data = $.extend({}, furthest.data(), data);
      furthest.data(data);
      $.publish('/updater/update', [furthest, data]);
    }
  }

  /* --------------------------------------------------------
   * loadTemplate
   * --------------------------------------------------------
   * loads new HTML template from server
   * This function is similar to the one below, it differs in a way that it adds the template in the view
   */
  function loadTemplate(e, data) {
    var insertInto = $(e.target).data('insertInto');
    var report_id = $(e.target).closest("form").find("#report_id").val();

    function success(html) {
      var suffix = '-fieldset';
      var $wrapper = $('#' + insertInto + suffix);
      var $insertedEl = $(html).appendTo($wrapper).not('script');

      // For some reasons adding a second arg here breaks many components listening to this event.
      $.publish('/updater/add', [$insertedEl.data('selective', true)]);
      $.publish('/updater/submit', [$insertedEl]);
      $.publish('/updater/add/ajax_template', [$insertedEl]);
      $.publish('/updater/add/deathToggler', [document]);
      $.publish('/updater/add/conditionalMultiChoices', [$insertedEl]);
      toggleNoAdverseEventButton();
    }

    data = $.extend({}, data, {template_type: insertInto}, {report: {id: report_id}});
    var templatePath = data.templatePath || '/' + s.locale + '/report/ajax_template';
    return $.post(templatePath, data, success, 'html');
  }

  /* --------------------------------------------------------
   * template
   * --------------------------------------------------------
   * loads new HTML template from server
  */
  function template(e, data, callback) {
    var report_id = $(e.target).closest("form").find("#report_id").val();
    data = $.extend({}, data, {report: {id: report_id}});

    function success(html, status, xhr) {
      if ($(e.target).data('insertInto')) {
        var random_id = xhr.getResponseHeader("X-random-id");
        data = $.extend({}, data, {random_id: random_id});
        loadTemplate(e, data);
      }
      if (html == 'session expired') {
        $.publish('/updater/expired');
      } else {
        updateTriggerMessage('post', data);
        callback(e, html);
      }
      $.publish('/updater/add/deathToggler', [document]);
      $.publish('/updater/add/conditionalMultiChoices', [$(e.target)]);
    }

    return fetchTemplate('post', data).done(success);
  }

  function updateTriggerMessage(method, data) {
    var templatePath;
    if (typeof method === 'object') {
      data = method;
      method = 'get';
    }
    method = method || 'get';
    data = data || {};
    templatePath = '/' + s.locale + '/report/update_trigger_message';
    return $[method](templatePath, data);
  }

  function fetchTemplate(method, data) {
    var templatePath;
    if (typeof method === 'object') {
      data = method;
      method = 'get';
    }
    method = method || 'get';
    data = data || {};
    templatePath = data.templatePath || '/' + s.locale + '/report/template';
    return $[method](templatePath, data);
  }

  return {
    selector: s.selector,
    init: init,
    submit: submit,
    updateAddTriggers: updateAddTriggers,
    fetchTemplate: fetchTemplate,
    toggleNoAdverseEventButton: toggleNoAdverseEventButton
  };

}(jQuery, window.I18n));
